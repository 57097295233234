import {
  useGetUserLazyQuery,
  GetUserQueryHookResult,
} from 'Generated/graphql';
import { useEffect, useState } from 'react';

export type UseUser = NonNullable<
  GetUserQueryHookResult['data']
>['getUser']

const useUser = (ownerUid?: string): UseUser | undefined => {
  const [getUser, getUserQuery] = useGetUserLazyQuery();
  const [user, setUser] = useState<UseUser>();

  useEffect(() => {
    if (ownerUid) {
      getUser({
        variables: {
          uid: ownerUid,
        }
      });
    }
  }, [ownerUid]);

  useEffect(() => {
    const { data } = getUserQuery;
    if (data?.getUser) {
      setUser(data.getUser);
    }
  }, [getUserQuery.data]);

  return user;
};

export default useUser;
