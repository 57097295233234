import { Alert } from 'antd';

type LoginPageErrorMessageProps = {
  message: React.ReactNode;
}

const LoginPageErrorMessage: React.FC<LoginPageErrorMessageProps> = ({
  message,
}) => {
  return (
    <Alert
      type="error"
      message={(
        <div className="text-center">
          {message}
        </div>
      )}
    />
  );
};

export default LoginPageErrorMessage;

