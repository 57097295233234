import GoogleG from 'Images/svg/google-g.svg';

type SigninWithGoogleProps = {
  onClick: () => void;
  copyPrefix?: string;
}

const SigninWithGoogle: React.FC<SigninWithGoogleProps> = ({
  onClick,
  copyPrefix = 'Sign in',
}) => {
  return (
    <div
      className="w-full rounded-[2px] cursor-pointer flex items-center justify-center p-2 gap-4 border border-gray-200 border-solid"
      onClick={onClick}
      data-testid="signinWithGoogle"
    >
      <div className="w-[35px] h-[35px]">
        <GoogleG className="scale-75" />
      </div>
      <span className="font-bold text-lg">
        {copyPrefix} with Google
      </span>
    </div>
  );
};

export default SigninWithGoogle;
