import { ApolloClient } from '@apollo/client';
import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryResult,
} from 'Generated/graphql';
import { AppRoutePath } from 'Pages/routes';
import { AccountType } from './AccountType';
import { AppCookie } from './Cookie';

export type GetUser = NonNullable<GetUserQueryResult['data']>['getUser']

export const getUserServerSide = async (
  queryFn: ApolloClient<object>['query'],
  sessionJwt: string | undefined,
): Promise<GetUser | undefined> => {
  return queryFn<GetUserQuery | undefined>({
    fetchPolicy: 'no-cache',
    query: GetUserDocument,
    context: sessionJwt ? {
      headers: {
        cookie: `${AppCookie.PeazySession}=${sessionJwt}`,
      }
    } : undefined,
  }).then((res) => {
    if (res?.error) {
      console.error(res.error);
    }

    if (res?.errors) {
      res.errors.forEach(e => {
        console.error(e);
      });
    }

    if (res?.data) {
      return res.data.getUser;
    }
  });
};

export const getDashboardPageForAccountType = (
  accountType: AccountType | undefined,
  currentPage: string | undefined,
  isHomeView: boolean,
) => {
  let destination = AppRoutePath.DASHBOARD;
  switch (true) {
    case accountType === AccountType.Customer ||
      accountType === AccountType._LegacyCustomer: {
      destination = AppRoutePath.HOME;
      break;
    }
    case accountType === AccountType.ServiceProvider: {
      destination = AppRoutePath.SERVICE_PROVIDER_DASHBOARD;
      break;
    }
    case accountType === AccountType.LocationOwner && isHomeView: {
      destination = AppRoutePath.BUSINESS_SCHEDULE;
      break;
    }
  }

  if (destination === currentPage) {
    return undefined;
  }

  return destination as string;
};

export const getDefaultPageForAccountType = (
  a: AccountType | undefined,
  currentPage: string | undefined,
) => {
  let destination = AppRoutePath.DASHBOARD;
  if (a === AccountType.Customer || a === AccountType._LegacyCustomer) {
    destination = AppRoutePath.BROWSE_SERVICE_TYPES;
  }

  if (a === AccountType.ServiceProvider) {
    destination = AppRoutePath.SERVICE_PROVIDER_DASHBOARD;
  }

  if (destination === currentPage) {
    return undefined;
  }

  return destination as string;
};

const getPostAuthRedirect = async (
  client: ApolloClient<object>,
  currentPage?: string,
  sessionJwt?: string,
): Promise<string | undefined> => {
  const user = await getUserServerSide(client.query, sessionJwt);
  const destination =
    getDefaultPageForAccountType(user?.accountType, currentPage);

  return destination;
};

export default getPostAuthRedirect;

