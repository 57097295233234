import {
  ApolloClient,
} from '@apollo/client';
import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryResult,
  GetUserQueryVariables,
} from 'Generated/graphql';
import { AppCookie } from './Cookie';
import Bugsnag from '@bugsnag/js';

export type GetUser =
  NonNullable<
    GetUserQueryResult['data']
  >['getUser']

const getUser = async (
  queryFn: ApolloClient<object>['query'],
  sessionJwt: string | undefined,
  ownerUid: string | undefined,
) => {
  const res =
    await queryFn<
      GetUserQuery | null,
      GetUserQueryVariables
    >({
      query: GetUserDocument,
      variables: ownerUid ? {
        uid: ownerUid,
      } : {},
      context: sessionJwt ? {
        headers: {
          cookie: `${AppCookie.PeazySession}=${sessionJwt}`,
        }
      } : undefined,
      fetchPolicy: 'no-cache',
    }).catch(Bugsnag.notify);

  return res?.data?.getUser;
};

export default getUser;

