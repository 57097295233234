import { parse } from 'cookie';
import { GetServerSidePropsContext } from 'next';
import { AppCookie } from './Cookie';

const getIsLoggedInCookieFromContext = (
  context: GetServerSidePropsContext,
): string | undefined => {
  const cookieHeader = context.req.headers?.cookie;
  const cookies = cookieHeader ?
    parse(cookieHeader) :
    {};

  return cookies[AppCookie.PeazyIsLoggedIn];
};

export default getIsLoggedInCookieFromContext;

