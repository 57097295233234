import React, { KeyboardEventHandler, useRef } from 'react';
import { Field, FieldProps, Form, Formik, FormikConfig, FormikValues } from 'formik';
import * as yup from 'yup';
import CenteredSpinner from '../CenteredSpinner';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import Link from 'next/link';
import { AppRoutePath } from 'Pages/routes';
import SigninWithGoogle from '../SigninWithGoogle';
import InputWithLabel from '../InputWithLabel';
import 'Clients/FireBaseClient';

type LoginFormProps = {
  onFirebaseSignInSuccess: (token: string) => void
  onSignInFailure: (message: string) => void
  isLoading: boolean
  precontent?: React.ReactNode;
  forceEmail?: string;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onFirebaseSignInSuccess,
  onSignInFailure,
  isLoading,
  precontent,
  forceEmail,
}) => {
  const auth = getAuth();
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const getInitialValue = () => ({
    email: forceEmail || '',
    password: ''
  });

  const validationSchema = yup.object().shape({
    email: yup.string()
      .email('Please enter valid email')
      .required('Please enter your email'),
    password: yup.string().min(6, 'Password must be at least 8 characters')
      .required('Please enter your password',
      ),
  });

  const formikCfg: FormikConfig<
    { email: string, password: string }
  > = {
    initialValues: getInitialValue(),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (
      values
    ) => {
      await signInWithEmailAndPassword(auth, values.email, values.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const idToken = await user.getIdToken();
          onFirebaseSignInSuccess(idToken);
        })
        .catch((error) => {
          console.error(error);
          const errorCode = error.code;
          onSignInFailure(errorCode);
        });
    },
    validationSchema: validationSchema
  };

  const handleSignInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const idToken = await user.getIdToken();
        onFirebaseSignInSuccess(idToken);
      }).catch((error) => {
        const errorCode = error.code;
        onSignInFailure(errorCode);
      });
  };

  const switchInputFocus: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      passwordInputRef.current?.focus();
    }
  };

  return (
    <Formik {...formikCfg}>
      {({ handleSubmit, isSubmitting }: FormikValues) => (
        <div
          className="flex flex-col gap-4 my-8 w-full md:w-3/4 px-6"
        >
          {precontent}
          <Form onSubmit={handleSubmit}>
            <div
              className="flex flex-col gap-6"
            >
              <div
                className={isSubmitting ? 'opacity-20 pointer-events-none' : ''}
              >
                <div className="mb-4">
                  <Field name="email">
                    {({ field, meta }: FieldProps) => (
                      <InputWithLabel
                        touched={meta.touched}
                        error={meta.error}
                        label="Email"
                      >
                        <input
                          data-testid="loginForm_emailInput"
                          data-cy="loginForm_emailInput"
                          {...field}
                          type="email"
                          onKeyDown={switchInputFocus}
                          name="email"
                          autoComplete="username"
                          className="input-lg"
                        />
                      </InputWithLabel>
                    )}
                  </Field>
                </div>
                <Field name="password">
                  {({ field, meta }: FieldProps) => (
                    <InputWithLabel
                      touched={meta.touched}
                      error={meta.error}
                      label="Password"
                    >
                      <input
                        data-testid="loginForm_passwordInput"
                        data-cy="loginForm_passwordInput"
                        {...field}
                        type="password"
                        ref={passwordInputRef}
                        autoComplete="current-password"
                        className="tracking-widest input-lg"
                      />
                    </InputWithLabel>
                  )}
                </Field>
              </div>
              <div
                className="flex flex-col gap-4 items-center"
              >
                <button
                  data-testid="loginForm_submit"
                  type="submit"
                  data-cy="loginForm_submit"
                  className="btn-primary w-full text-lg font-bold max-w-none"
                  disabled={isSubmitting || isLoading}
                >
                  {isSubmitting || isLoading ? <CenteredSpinner /> : 'Log In'}
                </button>
                <SigninWithGoogle
                  copyPrefix="Log In"
                  onClick={() => handleSignInWithGoogle()}
                />
              </div>
              <div className="flex justify-between">
                <Link
                  href={AppRoutePath.FORGOT_PASSWORD}
                  className="text-xs text-primary"
                >
                  Forgot your password?
                </Link>
                <Link
                  href={AppRoutePath.CUSTOMER_SIGNUP}
                  className="text-xs text-primary"
                >
                  Need an account? Sign up here.
                </Link>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default LoginForm;
