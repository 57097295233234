'use client';
import { useState, useMemo, useEffect } from 'react';
import { AppRoutePath } from 'Pages/routes';
import { useRouter, useSearchParams } from 'next/navigation';
import { SearchParam } from 'Common/functions/Search';
import LoginForm from 'Common/components/LoginForm';
import { useApolloClient } from '@apollo/client';
import {
  getSigninErrorMessageFromErrorCode,
  onAppSignInSuccess,
  handleAccountMigrationIfNeeded,
  postSigninRedirect,
} from 'Common/functions/LoginPage';
import LoginPageErrorMessage from
  'Common/components/LoginPage/LoginPageErrorMessage';
import {
  useV2Gateway_MigrateAnonymousIdentityMutation,
} from 'Generated/graphql';
import signinWithIdToken from 'Common/functions/signinWithIdToken';
import Link from 'next/link';
import useUser from 'Common/hooks/useUser';
import { getDefaultPageForAccountType } from 'Common/functions/getPostAuthRedirect';

const LoginPageContent = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [_key, _setKey] = useState(0);
  const query = useSearchParams();
  const returnTo = query.get(SearchParam.RETURN_TO) as string | undefined;
  const forceEmail = query.get(SearchParam.LOGIN_FORCE_EMAIL) as string | undefined;
  const urlParams = query.toString();
  const urlParamsRaw = urlParams ? `?${urlParams}` : '';

  const client = useApolloClient();
  const [migrateAnonymousAccount] =
    useV2Gateway_MigrateAnonymousIdentityMutation();
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>('');

  const signupUrl = `${AppRoutePath.CUSTOMER_SIGNUP}${urlParamsRaw}`;
  const noAccountError = (
    <span>
      Sorry, looks like we don&apos;t have that email address
      associated with an existing account.&nbsp;
      <Link href={signupUrl} className="link">
        Create your free account now
      </Link>
    </span>
  );

  const postSignupMergeAccountIdToken = useMemo(() => {
    const token =
      query.get(SearchParam.POST_SIGNUP_MIGRATE_ANONYMOUS_ACCOUNT_ID_TOKEN);
    return token ? String(token) : undefined;
  }, [query]);

  const doMigrateAnonymousAccount = async (
    anonymousIdToken: string,
    ownerIdToken: string,
  ) => {
    await migrateAnonymousAccount({
      variables: {
        anonymousIdToken,
        ownerIdToken,
      }
    });
  };

  // If the user is getting redirected somewhere after login, auto create their
  // account on login attempt if they don't have one
  const shouldCreateIfNotExists = useMemo(
    () => Boolean(returnTo),
    [returnTo],
  );

  const handleSigninError = (errorCode: string) => {
    if (errorCode.toLowerCase().includes('user-not-found')) {
      setErrorMessage(noAccountError);
      _setKey(_key + 1);
      return;
    }

    const msg = getSigninErrorMessageFromErrorCode(errorCode);
    setErrorMessage(msg);
  };

  const user = useUser();

  useEffect(() => {
    (async () => {
      if (user) {
        if (!user.isTemporary) {
          await client.clearStore();

          if (returnTo) {
            router.push(returnTo);
          } else {
            const url =
              getDefaultPageForAccountType(
                user.accountType,
                AppRoutePath.LOGIN
              );

            if (url) {
              router.push(url);
            }
          }
        }
      }
    })();
  }, [user?.uid]);

  return (
    <LoginForm
      forceEmail={forceEmail}
      precontent={errorMessage && (
        <LoginPageErrorMessage
          message={errorMessage}
        />
      )}
      onFirebaseSignInSuccess={token => {
        setErrorMessage('');
        setIsLoading(true);
        onAppSignInSuccess(
          token,
          signinWithIdToken,
          shouldCreateIfNotExists,
          client,
          () => {
            setIsLoading(false);
            setErrorMessage(noAccountError);
            _setKey(_key + 1);
          },
          async (res) => {
            await handleAccountMigrationIfNeeded(
              postSignupMergeAccountIdToken,
              token,
              doMigrateAnonymousAccount,
            );

            postSigninRedirect(
              {
                replace: router.replace,
                hardReplace: url => window.location.replace(url)
              },
              returnTo,
              res.accountType,
            );

            setTimeout(() => setIsLoading(false), 3000);
          },
        );
      }}
      onSignInFailure={handleSigninError}
      isLoading={isLoading}
    />
  );
};

export default LoginPageContent;

